import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import PortfolioContext from '../../context/context';
import ProjectImg from '../Image/ProjectImg';

const Projects = () => {
  const { projects } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Row>
            {projects.map((project) => {
              console.log(project);
              const { title, id, url, img, backgroundColor } = project;

              return (
                <Col key={id} lg={6} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      <Link to={url}>
                        <Tilt
                          options={{
                            reverse: false,
                            max: 8,
                            perspective: 1000,
                            scale: 1,
                            speed: 300,
                            transition: true,
                            axis: null,
                            reset: true,
                            easing: 'cubic-bezier(.03,.98,.52,.99)',
                          }}
                        >
                          <div
                            data-tilt
                            className="thumbnail rounded"
                            style={{
                              cursor: 'pointer',
                              backgroundColor,
                              padding: '15px',
                            }}
                          >
                            <div className="_overlay rounded">
                              <div className="_content">
                                <h1>{title}</h1>
                              </div>
                            </div>

                            <ProjectImg
                              imgStyle={{ objectFit: 'cover' }}
                              alt={title}
                              filename={img}
                            />
                          </div>
                        </Tilt>
                      </Link>
                    </div>
                  </Fade>
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Projects;
