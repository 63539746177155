import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';
import HeroImg from '../Image/HeroImg';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, img } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Row className="align-center">
          <Col xs={12} lg={6}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <h1 className="hero-title">
                {title || 'Hi, my name is'}{' '}
                <span className="text-color-main">{name || 'Your Name'}</span>
                <br />
                <span className="subtitle">{subtitle || "I'm the Unknown Developer."}</span>
              </h1>
            </Fade>
          </Col>
          <Col xs={{ span: 12, order: 'first' }} lg={{ span: 6, order: 'last' }}>
            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <div className="avatar">
                <HeroImg alt="profile avatar" filename={img} />
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Header;
